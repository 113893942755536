import { render, staticRenderFns } from "./stacks.vue?vue&type=template&id=3fc12898&scoped=true"
import script from "./stacks.vue?vue&type=script&lang=js"
export * from "./stacks.vue?vue&type=script&lang=js"
import style0 from "./stacks.vue?vue&type=style&index=0&id=3fc12898&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fc12898",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText})
